import { faCircleExclamation, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	capitalizeFirst,
	formatDate,
	getMinBookingTime,
	getStartAndEndTime,
} from 'common/Util'
import {
	ContentHeader,
	Form,
	FormField,
	FormSearchSelect,
	LoadingMaskWrap,
	SubmitButton,
	FormTextInput
} from 'components'
import * as Yup from 'yup'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { useForm } from 'react-hook-form'
import 'react-phone-number-input/style.css'
import { Link, useNavigate } from 'react-router-dom'
import { BookingContext } from '..'
import { AppointmentDetailsCard } from '../components/AppointmentDetailsCard'
import { UnselectedPatientModal } from '../components/UnselectedPatientModal'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { API_URL, TOKEN } from 'shared/config'


export const ConfirmAppointment = () => {
	const [showBookingTimeError, setShowBookingTimeError] = useState(false)
	const [passedBooking, setPassedBooking] = useState<any[]>([])
	const [unselectedPatient, setUnselectedPatient] = useState<any[]>([])
	const [showUnselectedPatientModal, setShowUnselectedPatientModal] =
		useState<boolean>(false)
	const [error, setError] = useState(false);
	const [isDiscountCheckComplete, setIsDiscountCheckComplete] = useState(true);
	const [isDiscount, setIsDiscount] = useState(false);	
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const {
		handleSubmitAll,
		partnerDetail,
		bookingInfo,
		patientDetail,
		isConfirmPageLoading,
		setDiscount,
		discountCode
	} = useContext(BookingContext)

	const validationSchema = Yup.lazy((value) => {
		const shapes: any = {}
		const DATA_OBJ_KEYS = Object.keys(value)

		DATA_OBJ_KEYS.forEach((parameter: any) => {
			if (parameter.includes('appointment_')) {
				shapes[parameter] = Yup.string()
					.required('Please select a patient.')
					.nullable()
			}
		})

		return Yup.object().shape(shapes)
	})

	const useFormInstance = useForm({
		...(patientDetail.personalInfo.length > 1 && {
			resolver: yupResolver(validationSchema),
		}),
		defaultValues: {
			isModal: false,
		},
	})
	const { getValues, setValue, register } = useFormInstance

	useEffect(() => {
		if (unselectedPatient.length > 0) {
			setShowUnselectedPatientModal(true)
		}
	}, [unselectedPatient])

	useEffect(() => {
		if (isConfirmPageLoading) {
			setShowUnselectedPatientModal(false)
		}
	}, [isConfirmPageLoading])

	useEffect(() => {
		if (passedBooking.length > 0) {
			setShowBookingTimeError(true)
		}
	}, [passedBooking])

	const checkIfPastTime = (info: any) => {
		const today = new Date()
		var todayMoment = formatDate(today)
		const currentTime = getMinBookingTime()

		let hasPassed = false
		if (info?.bookingDate === todayMoment) {
			hasPassed = info.bookingTime <= currentTime
		}
		return hasPassed
	}

	const checkPassedBooks = (info: any, arr: any) => {
		const passedTime = checkIfPastTime(info)
		if (passedTime) {
			arr.push(info.name)
		}
	}

	const headers = {
		'Content-Type': 'multipart/form-data',
		Authorization: `Token ${TOKEN}`,
	}

	const [bookingInfoNew, setBookingInfoNew] = useState(bookingInfo);
	const [bookingInfoID, setBookingInfoID] = useState();
	const [responseData, setResponseData] = useState();

	useEffect(() => {
		bookingInfoNew.map((info: any, index: number) => {
			if (info.id === bookingInfoID /*&& info.error === false*/) {
				updateDiscount(bookingInfoID, responseData); 
			}
		});
	  }, [bookingInfoNew, bookingInfoID, responseData]);

	const updateDiscount = (id: any, discountData: any) => {
		setDiscount((prevDiscounts: any) => ({
		  ...prevDiscounts,
		  [id]: discountData,
		}));
	  };
	  
	const handleMembershipCodeChange = async (id: any, service: any, membershipCode: any, bookingDate: any) => {

		setIsDiscount(true)
		setIsLoading(true)

		setBookingInfoNew((bookingInfo: any) => {
			const updatedBookingInfo = bookingInfo.map((info: any) => {
				if (info.id === id) {
					if (membershipCode == '') {
						return {
							...info,
							errorMessage: null,
							error: false,
						};
					}
				}
				
				return info;
			});

			setError(updatedBookingInfo.some((info: any) => info.error));
			setIsDiscountCheckComplete(true)
		
			return updatedBookingInfo;

		});

		try {
		  const response = await axios.get(
			`${API_URL}/discounts/?membership_code=${membershipCode.trim()}&services=${service}&expand=members`,
			{
			  headers,
			}
		  );

		  if (response.data.count > 0) {
			const members = response.data.results[0].members
			const bookingUser = members.find((member: any) => member.membership_id == membershipCode.trim());
		
			setBookingInfoNew((bookingInfo: any) => {
				const updatedBookingInfo = bookingInfo.map((info: any,  index: number) => {
					if (info.id === id) {
						const patientBdate = patientDetail.personalInfo.length > 1 ? moment(patientDetail.personalInfo[index].birthdate).format('YYYY-MM-DD') : moment(patientDetail.personalInfo[0].birthdate).format('YYYY-MM-DD')
						const patientGender = patientDetail.personalInfo.length > 1 ? patientDetail.personalInfo[index].gender == "Male" ? 1 : 2 : patientDetail.personalInfo[0].gender == "Male" ? 1 : 2
						if (membershipCode !== ''  && bookingUser && info.bookingDate < bookingUser.plan_termination_date
						&& patientBdate == bookingUser.date_of_birth && patientGender == bookingUser.gender) {
							return {
										...info,
										errorMessage: null,
										error: false,
									};
						}
						else{
							if (membershipCode !== ''  && bookingUser && info.bookingDate > bookingUser.plan_termination_date) {
								return {
									...info,
									errorMessage: 'Membership code has expired for this booking.',
									error: true,
								};
							}
							else if (membershipCode !== ''  && bookingUser && patientBdate != bookingUser.date_of_birth) {
								return {
									...info,
									errorMessage: 'Birthdate of booking user does not match with registered Member',
									error: true,
								};
							}
							else if (membershipCode !== ''  && bookingUser && patientGender != bookingUser.gender) {
								return {
									...info,
									errorMessage: 'Gender of booking user does not match with registered Member',
									error: true,
								};
							}

						}
						
					}
					
					return info;
				});
				
				setError(updatedBookingInfo.some((info: any) => info.error));
				setBookingInfoID(id)
				setResponseData(response.data.results[0])

				return updatedBookingInfo;
			});
		  }else{
			setBookingInfoNew((bookingInfo: any) => {
				const updatedBookingInfo = bookingInfo.map((info: any) => {
					if (info.id === id) {
						if (membershipCode !== '') {
							return {
								...info,
								errorMessage: 'Membership code does not exists or does not include this service.',
								error: true,
							};
						}
					}
					
					return info;
				});
			
				setError(updatedBookingInfo.some((info: any) => info.error));
			
				return updatedBookingInfo;
			});
		  }
		  setIsDiscountCheckComplete(true)
		  setIsLoading(false)	
		} catch (error) {
		  console.error(error)
		}
	  };

	const handleSubmit = async (isModal: boolean) => {
		const formValues: any = getValues()
		let unselected: any = []
		let passedBook: any = []		

		const appts: number[] = bookingInfo
				.map((m: any) => formValues[`appointment_${m.id}`])
				.filter((f: any) => f !== undefined)

		
		if (patientDetail.personalInfo.length > 1) {
			const appts: number[] = bookingInfo
				.map((m: any) => formValues[`appointment_${m.id}`])
				.filter((f: any) => f !== undefined)

			patientDetail.personalInfo.map((m: any, i: number) => {
				if (!appts.includes(i + 1)) {
					unselected.push({
						id: i + 1,
						name: `${m.firstName} ${m.lastName}`,
					})
				}
			})

			appts.map((m) => {
				const info = bookingInfo[m]
				checkPassedBooks(info, passedBook)
			})

			setUnselectedPatient(unselected)
		} else {
			checkPassedBooks(bookingInfo[0], passedBook)
		}

		if (passedBook.length > 0) {
			setPassedBooking(passedBook)
		}

		if (unselected.length === 0 || formValues['isModal']) {
			setShowUnselectedPatientModal(false)

			if (passedBook.length === 0) {
				handleSubmitAll(formValues)
			}
		}
	}

	return (
		<Container fluid>
			<ContentHeader
				title="Confirm Appointment"
				backText="Back"
				backLink={-1}
			/>
			<Form useFormInstance={useFormInstance} onSubmit={handleSubmit}>
				<Row className="justify-content-center">
					<Col lg={10}>
						<div className="bg-primary p-4">
							<FontAwesomeIcon
								icon={faCircleExclamation}
								className="text-secondary me-2"
								size="1x"
							/>
							<span>
								You have <strong>5 minutes</strong> to complete
								and submit this form. Failure to do so will
								release your chosen time slots.
							</span>
						</div>
					</Col>
				</Row>
				<Row className="my-5 justify-content-center">
					<Col lg={8}>
						<h5>Who are these appointments for?</h5>
						{bookingInfoNew &&
							bookingInfoNew.map((info: any, i: number) => (
								<AppointmentDetailsCard
									key={i}
									//affiliate={affiliateCode}
									service={info.name}
									price={info.price}
									partner={partnerDetail?.name}
									location={`${partnerDetail.street}${
										partnerDetail.unit_floor_building ===
										null
											? ''
											: ` ${partnerDetail.unit_floor_building}`
									}, ${partnerDetail.city}, ${partnerDetail.state_acronym} , ${
										partnerDetail.zip_code
									}`}
									time={getStartAndEndTime(
										info.bookingTime,
										info.duration
									)}
									date={moment(info.bookingDate).format(
										'dddd, MMMM DD, YYYY'
									)}
								>
									<FormField name={`appointment_${info.id}`}>
										<FormSearchSelect
											name={`appointment_${info.id}`}
											register={register}
											placeholder="Patient Name"
											options={patientDetail.personalInfo.map(
												(patient: any, i: number) => {
													return {
														label: [
															capitalizeFirst(
																patient.firstName
															),
															capitalizeFirst(
																patient.lastName
															),
														].join(' '),
														value: i + 1,
													}
												}
											)}
											{...(patientDetail.personalInfo
												.length === 1 && {
												defaultValue: {
													label: [
														patientDetail
															.personalInfo[0]
															.firstName,
														patientDetail
															.personalInfo[0]
															.lastName,
													].join(' '),
													value: 1,
												},
												setToDefaultValue: true,
											})}
											disabled={
												patientDetail.personalInfo
													.length === 1
											}
										/>
										{/* <FormTextInput
											placeholder="Discount Code"
											name={`discount_${info.id}`}
											register={register}
											onChange={(e) => {
											const membershipCode = e.target.value;
											handleMembershipCodeChange(info.id, [info.serviceId], membershipCode, info.bookingDate);
											}}
											hasError={info.error} 
											errorMessage={info.errorMessage}
											bookingDiscount={`discount_${info.id}`}
										/> */}

									</FormField>
								</AppointmentDetailsCard>
							))}

						<>
							<div className="mt-5 d-flex justify-content-center">
								<div className="footer">
									<SubmitButton
										pending={isConfirmPageLoading}
										pendingText="Submitting"
										className="text-center"
										//disabled={isConfirmPageLoading || error || !isDiscountCheckComplete}
										disabled={isConfirmPageLoading || error || !isDiscountCheckComplete}
									>
										Submit
									</SubmitButton>
								</div>
							</div>
						</>

						{showBookingTimeError && (
							<Alert variant="danger" className="mt-4">
								The appointment time you selected has already
								passed. Please go{' '}
								<Link
									className="link-danger"
									to={'../select-time'}
								>
									back
								</Link>{' '}
								and choose another timeslot for your
								appointment.
							</Alert>
						)}
					</Col>
				</Row>
			</Form>
			<UnselectedPatientModal
				show={showUnselectedPatientModal}
				setShow={setShowUnselectedPatientModal}
				name={unselectedPatient}
				setValue={setValue}
				onClick={handleSubmit}
				disableProceed={
					unselectedPatient.length ===
					patientDetail.personalInfo.length
				}
			/>
			{isConfirmPageLoading && <LoadingMaskWrap />}
			{isLoading && <LoadingMaskWrap />}
		</Container>
	)
}
