import { Button, Card, CardSubtitle, CardTitle } from 'react-bootstrap';
import { PharmacyDataProps } from '../../definition';
import { ContactCard } from './ContactCard';

type SelectedPharmacyProps = {
  data: PharmacyDataProps;
};

export const SelectedPharmacy = ({ data }: SelectedPharmacyProps) => {
  return (
    <Card>
      <Card.Body>
        <CardTitle className='mt-2 fw-bold'>{data.text}</CardTitle>
        <CardSubtitle className='mb-3'>{data.place_name}</CardSubtitle>
        <ContactCard
          address={data.address.street + ", " + data.address.city + ", " + data.address.state + ", " + data.address.zip} 
          // hours='8:00 AM to 10:00 PM'
          // email={data.email}
          phone={data.phone_number}
        />
        {/* <div className='mt-3'>
          <Button variant='primary' className='me-2'>
            Website
          </Button>
          <Button variant='primary'>Call</Button>
        </div> */}
      </Card.Body>
    </Card>
  );
};
