import mapboxgl, { Map, Marker } from 'mapbox-gl';
import { MutableRefObject, useEffect, useState, useCallback } from 'react';
import { MapboxEndpoint, PharmacyDataProps, GeoJSONFeatureCollection } from '../definition';
import { PopupMarker } from '../components/service-selection/PopupMarker';
import { API_URL, TOKEN, MAPBOX_KEY } from 'shared/config';
import axios from 'axios';
import { add, debounce } from 'lodash';
import logo from './makorxlogopin.png';
import './marker.css';

mapboxgl.accessToken = MAPBOX_KEY as string;

interface CurrentLocation {
  coordinates: [number, number];
}

type PharmacyPopup = {
  map: Map;
  pharmacyData: PharmacyDataProps;
  markerRef: MutableRefObject<Marker[]>;
  onClickPharmacy: (pharmacyData: PharmacyDataProps) => void;
  searchLocation: any
};

type GetPharmacyProps = {
  mapRef: MutableRefObject<Map | null>;
  locationCoords: [number, number];
  token: string;
  markerRef: MutableRefObject<Marker[]>;
  abortController: AbortController;
  selectedService: string | null;
  searchLocation: any;
};

const fetchUserCoordinates = async (locationCoords: [number, number]) => {
  try {
    const response = await fetch(
      `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${locationCoords[0]}&latitude=${locationCoords[1]}&access_token=${mapboxgl.accessToken}`
    );
    const data = await response.json();
    return data.features[0]?.properties?.context || {};
    
  } catch (error) {
    console.error("Error fetching user coordinates: ", error);
    return {};
  }
};

const getCoordinatesFromAddress = async (address: any) => {
  // const places = fetchedLocations.map((location: any) => {
  //   return {
  //     "types": ["address"],
  //     "q": location.place,
  //   };
  // });
  // console.log(places)
  // try {
  //   const response = await axios.post(`https://api.mapbox.com/search/geocode/v6/batch?&access_token=${mapboxgl.accessToken}`, places, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   console.log(response.data.batch)
  //   return response.data.batch;

  // } catch (error) {
  //   console.error("Error fetching coordinates from address: ", error);
  //   return [0, 0];
  // }

  try {
    const response = await fetch(
      `https://api.mapbox.com/search/geocode/v6/forward?address_line1=${address.street}&place=${address.city}&state=${address.state}&country=${address.country}&access_token=${mapboxgl.accessToken}`
    );
    const data = await response.json();
    return data.features[0]?.geometry.coordinates || [0, 0];
  } catch (error) {
    console.error("Error fetching coordinates from address: ", error);
    return [0, 0];
  }
};

const fetchPharmacyData = async (params: any, regionCode: any) => {
  try {
    const headers = {
      Authorization: `Token ${TOKEN}`,
    };
    const response = await axios.get(`${API_URL}/partners/`, {
      params,
      headers,
    });
    const formattedLocations = response.data.results.map((result: any) => {
      if (response.data) { 
        return {
          id: result.id,
          place_name: result.name,
          email: result.email,
          phone_number: result.phone_number,
          text: result.name,
          geometry: { coordinates: [] }, 
          place:`${result.street}, ${result.city}, ${result.state}, ${result.zip_code}`,
          category: 'pharmacy',
          address: {
            street: result.street,
            city: result.city,
            state: result.state,
            state_acronym: result.state_acronym,
            zip: result.zip_code,
            result: result.country,
          },
          services: result.services.filter((service: { state: any; })  => service.state === regionCode), 
        };
      } else {
        console.warn('Missing data in a result:', result);
        return null; 
      }
    }).filter(Boolean) as PharmacyDataProps[]; 

    return formattedLocations; 
    // return response.data.results || [];
  } catch (error) {
    console.error("Error fetching pharmacy data: ", error);
    return [];
  }
};

const usePharmacyData = (locationCoords: [number, number], selectedService: any, searchLocation: any) => {
  // console.log(searchLocation[0].properties.context.place.name.toString())
  const fetchDataAndProcess = async () => {
    try {
      // const userCoordinates = await fetchUserCoordinates(locationCoords);
      const userCoordinates = searchLocation ? await fetchUserCoordinates(searchLocation[0].geometry.coordinates) : await fetchUserCoordinates(locationCoords);
      const city = userCoordinates?.place?.name || '';
      const zipCode = userCoordinates?.postcode?.name || '';
      const regionCode = userCoordinates?.region?.region_code || '';
      // const params = selectedService || searchLocation
      //   ? { zip_or_city: searchLocation[0].properties.context.place.name.toString() !== null ? searchLocation[0].properties.context.place.name.toString()
      //      : '', service: selectedService !== null ? selectedService : '', is_approved: true, is_verified: true, is_bookable: true }
      //   : { zip_code: zipCode, city: city, is_approved: true, is_verified: true, is_bookable: true };

      const params = {
        zip_or_city: '',
        service: '',
        is_approved: true,
        is_verified: true,
        is_bookable: true,
      };
      
      if (searchLocation && searchLocation[0] && searchLocation[0].properties && searchLocation[0].properties.context && searchLocation[0].properties.context.place && searchLocation[0].properties.context.place.name) {
        params.zip_or_city = searchLocation[0].properties.context.place.name.toString();
      }
      
      if (selectedService) {
        params.service = selectedService;
      }
      
        const searchParams = new URLSearchParams();

        Object.entries(params).forEach(([key, value]) => {
          searchParams.append(key, typeof value === 'boolean' ? value.toString() : value);
        });

        // Object.entries(params).forEach(([key, value]) => {
        //   if (value !== undefined) {
        //     searchParams.append(key, value);
        //   }
        // });
        const expandParams = ['services.service', 'partner_configuration.configuration.days'];
        
        expandParams.forEach(expand => searchParams.append('expand', expand));
      
      const fetchedLocations = await fetchPharmacyData(searchParams,regionCode);
      // console.log(fetchedLocations)
      // const geocodedLocations = await getCoordinatesFromAddress(fetchedLocations);
      // const validCoordinatesArray = geocodedLocations.map((location: any, index:any) => {
      //   console.log(location.features[0].geometry.coordinates)
      //   const [lng, lat] = location.features[0].geometry.coordinates
      //   fetchedLocations[index].geometry = { coordinates: [lng, lat] };
      //   return { coordinates: [lng, lat] }; 
      // });

      // console.log(validCoordinatesArray)

      const validCoordinatesArray = await Promise.all(
        fetchedLocations.map(async (location: any, index) => {
          if (location.geometry?.coordinates?.length) {
            return location.geometry.coordinates;
          } else {
            const [lng, lat] = await getCoordinatesFromAddress(location.address);
            fetchedLocations[index].geometry = { coordinates: [lng, lat] };
            // location.geometry = { coordinates: [lng, lat] };
            return { coordinates: [lng, lat] };
          }
        })
      );

      const features: GeoJSONFeatureCollection['features'] = validCoordinatesArray.map((coordinates:any, index:any) => ({
        type: 'Feature',
        geometry: { 
          type: 'Point', 
          coordinates: coordinates as [number, number] 
        },
        properties: fetchedLocations[index],
      }));

      return {
        geojsonData: { type: 'FeatureCollection', features },
        pharmacyLocations: fetchedLocations,
      };
    } catch (error) {
      console.error("Error fetching and processing data: ", error);
      return { geojsonData: { type: 'FeatureCollection', features: [] }, pharmacyLocations: [] };
    }
  }; // 300ms debounce

  return fetchDataAndProcess;
};


const processPharmacyData = ({ map, markerRef, pharmacyData, onClickPharmacy, searchLocation }: PharmacyPopup): void => {

  if (pharmacyData.id === 'dummy-id') {
    map.flyTo({
      center: searchLocation[0].geometry.coordinates, 
      zoom: 15, 
    });
    return; 
  }

  const { coordinates } = pharmacyData.geometry;
  // console.log(searchLocation)
  // const el = document.createElement('div');
  // el.className = 'marker';
  // const img = new Image();
  // img.src = logo;
  // img.onload = () => {
  //   el.className = 'marker';
  //   el.style.backgroundImage = `url(${img.src})`;
  
  //   const marker = new Marker(el)
  //     .setLngLat(coordinates)
  //     .addTo(map);
  //     markerRef.current.push(marker);
  
  // };
    
  // const marker = new Marker(el) 
  // .setLngLat(coordinates)
  // .addTo(map);

  const marker = new Marker().setLngLat(coordinates).addTo(map);
  markerRef.current.push(marker);

  if (markerRef.current.length > 0) {
    const bounds = markerRef.current.reduce(
      (bounds: mapboxgl.LngLatBounds, marker) => bounds.extend(marker.getLngLat()),
      new mapboxgl.LngLatBounds()
    );
    map.fitBounds(bounds, { padding: 100, maxZoom: 14 });
  }

  PopupMarker({
    map,
    marker,
    popupText: `<small>${pharmacyData.category.toUpperCase()}</small><br><strong>${pharmacyData.place_name}</strong><br><small>${pharmacyData.place}</small>`,
    pharmacyId: pharmacyData.id,
    // onClickPharmacy: () => onClickPharmacy(pharmacyData),
    onClickPharmacy: () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
      onClickPharmacy(pharmacyData);
    },
  });
};

const getPharmacy = async ({
  mapRef,
  locationCoords,
  token,
  markerRef,
  abortController,
  onClickPharmacy,
  selectedService,
  searchLocation,
}: GetPharmacyProps & { onClickPharmacy: (pharmacyData: PharmacyDataProps) => void }): Promise<PharmacyDataProps[] | undefined> => {
  markerRef.current.forEach((marker) => marker.remove());
  markerRef.current = [];

  try {
    const fetchPharmacyData = usePharmacyData(locationCoords, selectedService, searchLocation);
    const data = await fetchPharmacyData();
    if (data.geojsonData.features.length !== 0) {
      data.geojsonData.features.forEach((feature) => {
        if (feature.properties) {
          processPharmacyData({
            map: mapRef.current!,
            pharmacyData: feature.properties as PharmacyDataProps,
            markerRef,
            onClickPharmacy,
            searchLocation
          });
        }
      });
      return data.pharmacyLocations;
    }else{
      const dummyPharmacyData: PharmacyDataProps = {
        id: 'dummy-id',
        place_name: 'Dummy Pharmacy',
        email: 'dummy@pharmacy.com',
        phone_number: '123-456-7890',
        text: 'This is a dummy pharmacy.',
        geometry: {
          coordinates: searchLocation[0].geometry.coordinates,
        },
        place: 'Dummy Place',
        category: 'Health',
        address: {
          street: '123 Dummy Street',
          city: 'Dummy City',
          state: 'Dummy State',
          state_acronym: 'DS',
          zip: '12345',
          country: 'Dummy Country',
        },
        services: [], 
      };
      processPharmacyData({
        map: mapRef.current!,
        pharmacyData: dummyPharmacyData,
        markerRef,
        onClickPharmacy,
        searchLocation
      });
    }
  } catch (error) {
    if (error instanceof Error && error.name === 'AbortError') {
      console.log('Fetch aborted');
    } else {
      console.error('Error fetching pharmacy data:', error);
    }
  }
};

export default getPharmacy;