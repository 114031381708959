import Form from 'react-bootstrap/Form';

type ServicesDropdownProps = {
  services: any[];
  setSelectedServiceOption: (serviceId: string | null) => void;
};
export const ServicesDropdown = ({ services, setSelectedServiceOption }: ServicesDropdownProps) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedServiceOption(selectedValue === 'Filter services' ? null : selectedValue);
  };

  return (
    <Form.Select
      style={{ border: '1px solid lightgray' }}
      aria-label="Default select example"
      className="w-100 filter__service___dropdown"
      defaultValue="Filter services"
      onChange={handleSelectChange}  
    >
      <option value=''>
        All services
      </option>
      services={services.map(service => (
        <option key={service.id} value={service.id}>
          {/* {service.id} */}
          {service.name} 
        </option>
      ))}
    </Form.Select>
  );
};
