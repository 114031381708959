import { useMapBox } from '../hooks/useMapBox';
import { HeaderWithSubtitle } from '../components/ui/HeaderWithSubtitle';
import { useState, useEffect, useContext } from 'react';
import { useServiceContext } from '../hooks/useServiceContext';
import { pharmacyServices } from '../lib/data';
import { Form, Button, Col, Row, Spinner, Container } from 'react-bootstrap';
import { SelectedPharmacy } from '../components/service-selection/SelectedPharmacy';
import { ServiceCard } from '../components/service-selection/ServiceCard';
import { v4 as uuidv4 } from 'uuid';
import { ServicesDropdown } from '../components/service-selection/ServicesDropdown';
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { TotalPrice } from '../components/service-selection/TotalPrice';
import { BookingContext } from '../../'
import axios from 'axios'
import { API_URL,TOKEN, MAPBOX_KEY } from 'shared/config'
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses';
import mapboxgl, { Map, Marker } from 'mapbox-gl';
import { SearchBox } from '@mapbox/search-js-react';
import { Alert } from 'react-bootstrap';


export const ServiceSelection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedServiceOption, setSelectedServiceOption] = useState<string | null>(null);
  const [searchInput, setSearchInput] = useState<any>();
  // const [myString, setMyString] = useState<any[]>([]);
  const [newSearch, setNewSearch] = useState<string>('');
  const { mapContainer, pharmacyData, pharmacyDataOnClick, setSelectedService, setSearchLocation, searchLocation, selectedService } = useMapBox();
  const { listOfServices, selectedPharmacy, setSelectedPharmacy, serviceTotalPrice } = useServiceContext();
  const [pharmacyServices, setPharmacyServices] = useState<any[]>([])
  const navigate = useNavigate()

  mapboxgl.accessToken = MAPBOX_KEY as string;
  
  const {
		appointmentInfo,
		setPartnerInfo,
		partnerInfo,
		headers,
		setPartnerDetail,
    setServiceDetail,
		partnerDetail,
		serviceDetail,
		serviceCounters,
    setBookingInfo,
	} = useContext(BookingContext)

  useEffect(() => {
    if (pharmacyDataOnClick) {
      setPartnerInfo(selectedPharmacy)
      setIsLoading(true);
      setSelectedPharmacy({
        ...pharmacyDataOnClick,
        services: pharmacyDataOnClick.services,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [pharmacyDataOnClick, setSelectedPharmacy]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const headers = {
          Authorization: `Token ${TOKEN}`,
        };
    
        const apiResponse = await axios.get(
          `${API_URL}/services`,
          { headers }
        )
        setPharmacyServices(apiResponse.data.results)
      }
      catch (error) {
        console.error("Error fetching coordinates or pharmacy data: ", error);
        return []; 
      }
    };

    fetchServices(); 
  }, []); 

  //go to next page
  const handleSubmit = () => {
    const selectedServices = Object.values(allSelectedServices).map(service => service.service.id);
    const commaSeparatedIds = selectedServices.join(',');
    let container: any = []
    let apptId: number = 1
    const newBookingInfo = Object.values(allSelectedServices).map(service => {
      return {
        id: apptId++,
        serviceId: service.service.id,
        bookingDate: null,
        bookingTime: null,
        name: service.service.name,
        price: service.price,
        duration: service.service.duration,
        description: service.description,
        serviceIdDB: service.id
      };
  });
  container.push(...newBookingInfo); 
    axios
			.get(
				// `${API_URL}/partners/?city=${appointmentInfo.city}&is_approved=true&is_verified=true&expand=type`,
				`${API_URL}/partners/${selectedPharmacy?.id}?&expand=services.service&is_approved=true&is_verified=true&is_bookable=true&service=${commaSeparatedIds}&expand=partner_configuration.configuration.days`,
				{
					headers,
				}
			)
			.then((response) => {
        setPartnerInfo(response.data)
        setPartnerDetail(response.data)
        setBookingInfo(container)
        setServiceDetail(allSelectedServices)
			})
		return new Promise(() => {
			setTimeout(() => {
				navigate('../select-time')
			}, 500)
		})
	}

const handleSearchClick = () => {
    setSelectedService(selectedServiceOption); 
    setSearchLocation(searchInput);  
    // setSearchLocation('');          
};

const allSelectedServices = listOfServices.flatMap(service => 
    service.selected ? Array(service.quantity).fill(service) : []
);
 
const uniqueSelectedServices = [];
const serviceIdsSeen = new Set();

for (const service of allSelectedServices) {
  if (!serviceIdsSeen.has(service.id)) {
    uniqueSelectedServices.push(service);
    serviceIdsSeen.add(service.id);
  }
}

const handleInputChange = (event:any) => {
  setSearchInput(event.target.value);
};

useEffect(() => {     
  setSearchLocation(searchInput);  
  setSelectedService(selectedServiceOption); 
}, [searchInput,selectedServiceOption]);

// return (
//   <>
//    <Container className="mx-2 mb-2">
//   <Row className="justify-content-center justify-content-md-end align-items-center">
//     <Col xs={12} className="mb-2">
//       <HeaderWithSubtitle
//         title="Find a local pharmacy to book the service you need"
//       />
//       <Form.Text>
//         Enter your city and state or zip code and select your service then click search.
//         Click the pharmacy pin on the map to see the services offered below.
//       </Form.Text>
//     </Col>
//   </Row>
//   <Row className="justify-content-center">
    
//     <Col xs={12} md="auto" className='d-flex flex-column flex-md-row align-items-center'>
      
//         <Col xs={12} md={9} className="mx-2 mb-2 mb-md-0">
//           <SearchBox
//             accessToken={mapboxgl.accessToken}
//             placeholder="Enter City and State or Zip Code"
//             // onRetrieve ={(d) => setSearchInput(d.features)}
//             onRetrieve ={(d) => setSearchInput(d.features)}
//             value={searchInput ? searchInput[0].properties.full_address : ''}
//             onChange={(d) => setNewSearch(d)}
//             // onChange={handleInputChange}
            
//           />
//         </Col>
//         <Col xs={12} md={6} className="mx-2 mb-2 mb-md-0">
//           <ServicesDropdown services={pharmacyServices} setSelectedServiceOption={setSelectedServiceOption} />
//         </Col>
//         {/* <Button variant="primary" onClick={handleSearchClick} className="mr-2">
//           Search
//         </Button> */}
      
//     </Col>
//   </Row>
// </Container>
//    {
//     pharmacyData === undefined ?
//     <Alert key='danger' variant='danger' className='text-center'>
//        No pharmacies in this area
//     </Alert> 
//       : ""
//    }       
//     <Row>
//     <div ref={mapContainer} className='mapboxgl' />
//     </Row>

    // <Row className='mt-4'>
    //   {selectedPharmacy ? (
    //     <>
    //       <Col >
    //         <HeaderWithSubtitle subtitle='Selected Pharmacy' />
    //         <SelectedPharmacy data={selectedPharmacy} />
    //       </Col>
    //       <Col xs={12} md={12}>
    //         <div>
    //           {isLoading ? (
    //             // <div className='d-flex justify-content-center align-items-center gap-1 mt-3'>
    //             <div>
    //               <Spinner
    //                 variant='primary'
    //                 animation='border'
    //                 size='sm'
    //                 role='status'
    //               />
    //               <span className='mt-2'>Retrieving Service Information...</span>
    //             </div>
    //           ) : (
    //             <>
    //               <Row xl={12} className='mt-2'>
    //                 <HeaderWithSubtitle
    //                   // subtitle={`Available Tests & Services in ${selectedPharmacy.text}`}
    //                   subtitle={`Available Tests & Services`}
    //                 />
    //               </Row>
    //               <Row xl={2}>
    //                 {/* Render a list of services offered by the selected pharmacy. */}
    //                 {selectedPharmacy.services.map((service) => (
    //                   <Col key={uuidv4()} className='mb-3' xs={12} md={12}>
    //                     <ServiceCard
    //                       data={{
    //                         ...selectedPharmacy,
    //                         availableServices: service,
    //                       }}
    //                     />
    //                   </Col>
    //                 ))}
    //               </Row>
    //             </>
    //           )}
    //           {isLoading ? null : allSelectedServices.length > 0 ? (
    //             <>
    //               <div className='mt-3 d-flex justify-content-end'>
    //                 <Button
    //                   variant='primary'
    //                   onClick={() => handleSubmit()}
    //                 >
    //                   Proceed to next step
    //                 </Button>
    //               </div>
    //             </>
    //           ) : (
    //             <div className='text-center mt-3'>
    //               <p className='text-muted'>No services selected</p>
    //             </div>
    //           )}
    //         </div>
    //       </Col>
    //     </>
    //   ) : (
    //     <Col className='text-center'>
    //       <p className='text-muted'>Select a pharmacy to view services</p>
    //     </Col>
    //   )}
    // </Row>
//   </>
// );
return (
  <>
    <Container className="mx-2 mb-2">
      <Row className="justify-content-center justify-content-md-end align-items-center">
       <HeaderWithSubtitle title="Find a local pharmacy to book the service you need"/>
       <p className="small text-left">
        Enter your city and state or zip code and select your service then click search.
        <br/>Click the pharmacy pin on the map to see the services offered below.
			 </p>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={6} className="mb-2">
            <SearchBox
              accessToken={mapboxgl.accessToken}
              placeholder="Enter City and State or Zip Code"
              onRetrieve={(d) => setSearchInput(d.features)}
              value={searchInput ? searchInput[0].properties.full_address : ''}
              onChange={(d) => setNewSearch(d)}
            />
        </Col>
        <Col xs={12} md={6} className="mb-2">
          <ServicesDropdown services={pharmacyServices} setSelectedServiceOption={setSelectedServiceOption} />
        </Col>
      </Row>

    {
    pharmacyData === undefined ?
      <Alert key='danger' variant='danger' className='text-center'>
        No pharmacies in this area
      </Alert> : "" 
    }       
    <Row>
      <div ref={mapContainer} className='mapboxgl' />
    </Row>
    <Row className='mt-4'>
      {selectedPharmacy ? (
        <>
          <Col >
            <HeaderWithSubtitle subtitle='Selected Pharmacy' />
            <SelectedPharmacy data={selectedPharmacy} />
          </Col>
          <Col xs={12} md={12}>
            <div>
              {isLoading ? (
                // <div className='d-flex justify-content-center align-items-center gap-1 mt-3'>
                <div>
                  <Spinner
                    variant='primary'
                    animation='border'
                    size='sm'
                    role='status'
                  />
                  <span className='mt-2'>Retrieving Service Information...</span>
                </div>
              ) : (
                <>
                  <Row xl={12} className='mt-2'>
                    <HeaderWithSubtitle
                      // subtitle={`Available Tests & Services in ${selectedPharmacy.text}`}
                      subtitle={`Available Tests & Services`}
                    />
                  </Row>
                  <Row xl={2}>
                    {/* Render a list of services offered by the selected pharmacy. */}
                    {selectedPharmacy.services.map((service) => (
                      <Col key={uuidv4()} className='mb-3' xs={12} md={12}>
                        <ServiceCard
                          data={{
                            ...selectedPharmacy,
                            availableServices: service,
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </>
              )}
              {isLoading ? null : allSelectedServices.length > 0 ? (
                <>
                  <div className='mt-3 d-flex justify-content-end'>
                    <Button
                      variant='primary'
                      onClick={() => handleSubmit()}
                    >
                      Proceed to next step
                    </Button>
                  </div>
                </>
              ) : (
                <div className='text-center mt-3'>
                  <p className='text-muted'>No services selected</p>
                </div>
              )}
            </div>
          </Col>
        </>
      ) : (
        <Col className='text-center'>
          <p className='text-muted'>Select a pharmacy to view services</p>
        </Col>
      )}
    </Row>   
      
    </Container>
  </>
  );
};
